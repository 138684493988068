import React, { useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap'
import ReactPlayer from 'react-player'
import styled, { css } from 'styled-components'
import { withRouter } from 'react-router-dom'
// local import
import OperatorCard from '../../components/events/OperatorCard'
import SummaryCard from '../../components/events/SummaryCard'
import Footer from '../../Layouts/Footer'
import RelatedExcursions from '../../components/sliders/RelatedExcursions'
import { GetExcursion } from '../../api/excursions'

function Detail(props) {
    const [blackscreen, handleVideo] = useState(false)
    const [handler, mapHandler] = useState(1)
    const [data, setData] = useState({
        operated_by: {},
        hotel: {},
        sessions: [],
        category: {},
    })
    const [video, setVideo] = useState('')

    useEffect(() => {
        const uid = props.match.params.uid
        GetExcursion(uid).then((response) => {
            setData(response.data)
            setVideo(`${response.data.video}`)
        })
    }, [])

    const handleVideoPlay = () => {
        setTimeout(() => {
            handleVideo(false)
        }, 300)
    }

    const handleMap = (val) => {
        mapHandler(val)
    }

    return (
        <>
            <Container className="container-fluid">
                <Row>
                    <Col xs={12} className="operator">
                        {/* <OperatorCard operator={data.operated_by} /> */}
                        <SummaryCard excursion={data} />
                    </Col>
                    <Col xs="12 mb-3" style={{ padding: 0 }}>
                        {handler === 1 &&
                            (data.video !== null && data.video !== '' ? (
                                <VideoContainer>
                                    {!blackscreen ? (
                                        <ReactPlayer
                                            loop
                                            controls={false}
                                            width="100%"
                                            height="550px"
                                            url={video}
                                            playing
                                            onClick={() => handleVideo(true)}
                                            onPlay={handleVideoPlay}
                                            onPause={() => handleVideo(true)}
                                        />
                                    ) : (
                                        <BlackScreen
                                            onClick={() => {
                                                console.log('pasoo')
                                                handleVideo(false)
                                            }}
                                            show={blackscreen}>
                                            <i className="fas fa-play"></i>
                                        </BlackScreen>
                                    )}
                                </VideoContainer>
                            ) : (
                                <img width="100%" src={data.featured_image} />
                            ))}
                        {handler === 2 && <img width="100%" src={data.itinerary} />}
                        {handler === 3 && <img width="100%" src={data.itinerary} />}
                    </Col>
                    <Col xs="12">
                        <RelatedExcursions excursion={data} />
                    </Col>
                </Row>
            </Container>
            <Footer excursion={data} showBack purchase onClick={handleMap} buttonActive={handler} />
        </>
    )
}
const BlackScreen = styled.div`
    position: absolute;
    height: 550px;
    width: 100%;
    background: #000;
    z-index: 9;
    /* pointer-events: none; */
    top: 0;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    color: #fff;
    transition: all 0.5s;
    display: none;
    ${(props) =>
        props.show &&
        css`
            display: flex;
        `}
    i {
        pointer-events: none;
    }
    :active {
        i {
            opacity: 0.5;
        }
    }
`
const VideoContainer = styled.div`
    margin-top: -5rem;
    position: relative;
    min-height: 550px;
    cursor: pointer;
    .ytp-pause-overlay,
    .ytp-scroll-min,
    .ytp-pause-overlay.ytp-scroll-min {
        display: none !important;
    }
`
const Container = styled.div`
    .flex-end {
        display: flex;
        justify-content: flex-end;
        text-align: right;
        i {
            margin-right: 10px;
            opacity: 0.8;
        }
    }
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    .media {
        img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
        }
    }
    margin-bottom: 300px;
    .operator {
        margin-top: 1rem;
        margin-bottom: 20px;
        background: #fff;
        z-index: 10;
        padding-bottom: 10px;
    }
`
const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
export default withRouter(Detail)
