import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import { GetSliders } from '../../api/advertisement'
import { withContext } from '../../context/withContext'

const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    speed: 500,
    fade: true,
    pauseOnHover: false,
    nextArrow: <span style={{ display: 'none !important' }} />,
    prevArrow: <span style={{ display: 'none !important' }} />,
}
function TopSlider(props) {
    const [sliders, setSlider] = React.useState([])
    const refslider = React.useRef(null)

    const GetSliders_ = async () => {
        let response = await GetSliders(`hotel=${props.context.hotel}&type=image`)
        setSlider(response.data)
        console.log(response.data, '🔥')
    }

    React.useEffect(() => {
        GetSliders_()
        let change = 0
        setInterval(() => {
            change++
            if (change === 8) {
                refslider.current.slickNext()
                change = 0
            }
        }, 2000)
    }, [])

    return (
        <FooterContainer>
            <Slider ref={refslider} {...settings}>
                {sliders.map((e, key) => (
                    <Item key={key}>
                        <img src={e.file} />
                    </Item>
                ))}
            </Slider>
        </FooterContainer>
    )
}

export default withContext(TopSlider)

const Item = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    img {
        width: 100%;
        object-fit: cover;
        height: 100px;
    }
`
const FooterContainer = styled.div`
    width: 100%;
`
