import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
// Local imports
import Topbar from './Topbar'
import Content from './Content'
import { withContext } from '../context/withContext'
import { withRouter, Redirect } from 'react-router-dom'
import AppLoader from '../components/AppLoader'
import Authentication from '../Pages/Authentication'
import { GetHotel } from '../api/core'
import styled from 'styled-components'
import { FilterCategories } from '../api/excursions'
import Cookies from 'universal-cookie'
import Advertisements from './Advertisements'

const cookies = new Cookies()

class Principal extends Component {
    constructor(props) {
        super()
        this.state = {
            screensaver: [],
            showScreensaver: false,
            screensaver_time: 300,
            has_screensaver: false,
            time: 0,
            redirect: null,
        }
        this.socket = null
    }

    connectSocket = (roomName, token, updateContextVal) => {
        const url =
            !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
                ? `ws:localhost:8000/ws/notifications/${roomName}/?token=${token}`
                : `wss://ws.puntacanaapp.com.do/ws/notifications/${roomName}/?token=${token}`
        this.socket = new WebSocket(url)

        this.socket.onmessage = function (e) {
            let data = JSON.parse(e.data)
            console.log(data)
            if (data.action === 'payment_success') {
                updateContextVal({
                    payment_status: 'payment_success',
                })
                window.location.href = `/thank-you/${data.reservation}`
                // this.setState({ redirect: `/thank-you/${data.reservation}` })
            }
            if (data.action === 'payment_failed') {
                updateContextVal({
                    payment_status: 'payment_failed',
                })
                this.setState({ redirect: `/declined` })
            }
            if (data.action === 'reload') {
                window.location.href = '/'
            }
        }.bind(this)

        this.socket.onclose = function (e) {
            console.error('Chat socket closed unexpectedly')
        }
    }

    disableContext = (tag) => {
        let noContext = null
        for (let x in tag) {
            noContext = document.getElementsByTagName(tag[x])
            for (let i = 0; i < noContext.length; i++) {
                noContext[i].addEventListener('contextmenu', (e) => {
                    e.preventDefault()
                })
            }
        }
    }

    getInitialData = async () => {
        let hotel = cookies.get('hotel')
        let response2 = await GetHotel(hotel)
        let response3 = await FilterCategories('')
        this.props.updateContextVal({
            logo1: response2.data.logo,
            logo2: response2.data.logo_alt,
            categories: response3.data,
        })

        setTimeout(() => {
            this.disableContext(['a', 'img', 'div', 'body', 'p'])
        }, 2000)
    }

    componentDidMount() {
        this.getInitialData()
        this.connectSocket(this.props.context.uid, this.props.context.token, this.props.updateContextVal)
    }

    componentWillUnmount() {
        // Desconectar el WebSocket cuando el componente se desmonta
        if (this.socket) {
            this.socket.close()
        }
    }

    render() {
        if (this.state.redirect !== null) {
            return <Redirect to={this.state.redirect} />
        } else {
            let isLogged = this.props.context.isLogged
            let loadingApp = this.props.context.loadingApp
            let totem_type = this.props.context.type
            return (
                <Div>
                    <BrowserRouter>
                        {loadingApp ? (
                            <AppLoader />
                        ) : isLogged ? (
                            totem_type === 1 ? (
                                <div>
                                    <Topbar user={this.props.user} />
                                    <Content user={this.props.user} />
                                </div>
                            ) : (
                                <div>
                                    <Advertisements user={this.props.user} />
                                </div>
                            )
                        ) : (
                            <Authentication />
                        )}
                    </BrowserRouter>
                </Div>
            )
        }
    }
}
export default withRouter(withContext(Principal))

const Div = styled.div`
    overflow-x: hidden;
    img {
        /* pointer-events: none; */
    }
`
const Screensaver = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    float: left;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #fff;
    img {
        object-fit: cover;
    }
    .slick-arrow {
        visibility: hidden !important;
        display: none !important;
    }
`

const ContentImage = styled.div`
    width: 100%;
    height: 100vh;
    background: url(${(props) => (props.bg ? props.bg : 'none')});
    background-repeat: none;
    background-size: cover;
    background-position: center center;
`
